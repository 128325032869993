import * as React from 'react'
import Layout from '../components/layout'
import ContentMargins from '../components/ContentMargins'
import { css } from '@emotion/core'
import { MenuSelector } from '../components/MenuSelector'
import { graphql, useStaticQuery } from 'gatsby'


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      info: sanityIndexPage(_id: {eq: "main-index"}) {
        quote {
          text,
          author
        },
        mainLinks {
          text,
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          },
          keypointLink {
            slug { current }
          },
          productPageLink {
            slug { current }
          },
          externalLink
        }
      }
    }
  `);

  const {quote, mainLinks} = data.info;

  return <Layout showNavBar={false}>
    <div>
        <MenuSelector links={mainLinks} />
    </div>


    <div css={css`
      background: red;
      margin: 1.5em 0.6em;
      
      background: #9b1031;
      color: white;
      
      background: whitesmoke;
      color: #9b1031;
      
      font-size: 7vmin;
      font-weight: bold;
      text-shadow: 0 0 66px #9b1031, 0 0 66px rgba(255,255,255,0.5), 0 0 66px rgba(255,255,255,0.5);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      
      .author {
        font-size: 3.4vmin;
        text-align: right;
        margin: 1.5em 0.5em 0 0;
      }
    `}>
      <ContentMargins>
        {quote.text}
        <div className={"author"}>{quote.author}</div>
      </ContentMargins>
    </div>
  </Layout>
}


export default IndexPage
